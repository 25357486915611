ul {
    font-size: var(--p-size);
    font-family: var(--font-main);
    padding: 0;
    margin: 0 1.5em;
    list-style-type: "—  ";
    li {
        padding: 0;
        margin: 0;
    }
}