@use "scss/_variables.scss";

@use "scss/_typo.scss";
@use "scss/_nav.scss";

@use "scss/_start.scss";
@use "scss/_cards.scss";
@use "scss/_project.scss";

@use "scss/_logo.scss";
@use "scss/_btn.scss";
@use "scss/_list.scss";
@use "scss/_footer.scss";

html,
body {
  //position: absolute;
  width: 100%;
  //min-height: 100vh;
  margin: 0;
}

body {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
  overflow: auto;
  font-family: var(--font-main);
  background-color: var(--bg);
  overflow-x: hidden;
  word-break: break-word;
  ::selection {
    background: var(--dark);
    color: var(--softYellow);
  }
}
.body-fixed {
  position: fixed;
  height: 100%;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 600ms;
  height: auto;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
}

h1,
h2,
h3 {
  line-height: 1;
}

.main {
  opacity: 0;
  position: fixed;
  display: block;
  padding: 4px;
  margin: 0px auto;
  max-width: var(--maxpagewidth);
  padding-top: 6rem;
  transition-delay: 0.1s;
  transition: 0.6s;
  transform: translateY(100vh);
  transform-origin: bottom;
}
figure,
picture {
  margin: 0;
  padding: 0;
}
hr {
  border: none;
  margin: 1em 0 3em 0;
  width: 100%;
  border-bottom: 1px solid black;
}
section {
  position: relative;
}

img {
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .main {
    padding-top: 25vh;
  }
}
