@font-face {
    font-family: 'True Sans medium';
    src: url("../fonts/TrueSansTrial-Medium.otf") format("opentype");
}
@font-face {
    font-family: 'IBM Plex Mono';
    src: url("../fonts/IBMPlexMono-Regular.ttf") format("truetype");
}

:root {
    scroll-behavior: smooth;
    -webkit-tap-highlight-color:transparent;
    // FONTS //
    --font-main: 'helvetica', sans-serif;
    --font-sec: 'True Sans medium', sans-serif;
    --font-mono: odisseia, sans-serif;
    // FONT-SIZE //
    --p-size: clamp(0.75rem, 1.5vh, 1rem);
    --large-size: clamp(1.5rem, 4vh, 2.6rem);
    --med-size: clamp(1.5rem, 2.5vh, 1.8rem);
    // COLOR //
    --yellow: rgb(255, 241, 111);
    --softYellow: rgb(255, 250, 177);
    --green: rgb(170, 255, 177);
    --pink: rgb(253, 191, 225);
    --bg: #fcfbef;
    --dark: #292929;
    // POSTER //
    --poster-padding: 40px;
    --buttonshade: rgb(30 30 30 / 20%) 0px 1px 6px -1px;
    --borderradius: 4px;
    --buttonradius: 0px;
    --maxpagewidth: 1800px;
}

@media screen and (min-height: 900px) {
    :root {
        --poster-padding: 60px !important;    
    }    
}
@media screen and (min-width: 1200px) {
    :root{
        --borderradius: 6px;
        --buttonradius: 3px;
    }
}
