.footer {
    display:block;
    bottom: 0;
    left: 0;
    right: 0;
    color:var(--dark);
    background-color: white;
    border-top: 1px solid black;
    box-shadow: var(--buttonshade);
    padding: .5rem 2rem;
    border-radius: var(--buttonradius);
    text-align: center;
    font-size: 0.7rem;
    text-decoration: none;
}