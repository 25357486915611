.project {
    position: fixed;
    margin: auto;
    max-width: var(--maxpagewidth);
    padding: 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    left: 0;
    right: 0;
}
.project::before{
    content: '';
    height: 3rem;
    position: relative;
    display: block;    
}




// swiper //
.swiper {
    width: 100%;
    height: calc(100% - 3rem);
    position: relative;
    margin: auto;
    display: block;
    overflow: visible;
    &.darker{
      filter: grayscale(80%) blur(10px); 
      opacity: .3;
    }
}
.swiper-slide {
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0;
    margin: auto;
    text-align: center;
    picture{
      width: 100%;
      height: 100%;
    }
    img{
        margin: auto;
        width: auto;
        position: absolute;
        display: block;
        top:0;
        bottom: 2.5rem;
        border-radius: var(--borderradius);
        left:0;
        right:0;
        max-width: 100%;
        max-height: calc(100% - 6rem);
    }
}

  
  .swiper-button-next, .swiper-button-prev{
    top: 0%;
    width: 50%;
    padding: 0;
    margin: 0;
    height: 80%;
    z-index: 200;
    &::selection {
      background: none;
    }
    &:focus, &:focus-visible{
      outline: none;
      border:none
    }
    &::after{
      font-family: var(--font-main);
      color: white;
      content: '';
    }
  } 
  .swiper-button-prev{
    left: 0;
    cursor: w-resize;
  }
  .swiper-button-next{
    right: 0;
    cursor: e-resize;
  }
  
  .swiper-pagination{
    color: var(--dark);
    display: block;
    top: 0;
    right: auto;
    left: 0;
    bottom: auto;
    width: auto;
    padding: 0 4px .5rem 4px;
    line-height: normal;
    font-size: 1rem;
    border-bottom: 1px solid var(--dark);
  }


.iframe__container {
  top: 0;
  background-color: var(--bg);
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5rem 4px;
  max-width: var(--maxpagewidth);
  position: absolute;
  margin: auto;
  display: none;
  overflow: visible;
  z-index: 300;
  .iframe__wrapper{
    display: flex; 
    height: 100%;
  }
  iframe{
    width: 100%;
  }
  &.show{display: block;}
}
.swiper-slide 
figcaption {
  position: fixed;
  bottom: 0;
  right: auto;
  font-size: 0.7rem;
  line-height: 1.6em;
  left: 0;
  width: calc(50% - 16px);
  text-align: left;
  display: block;
  margin: 0;
  padding: .5rem 4px;
  border-top: solid 1px var(--dark);
}    


@media screen and (min-width: 1200px) {
  .swiper {&.darker{filter: grayscale(0%); opacity: 1;}} 
}