.card__container{
    width: 100%;
    margin: 0;
    margin-bottom: 4rem;
    position: relative;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    a{text-decoration: none;}
}
.card {
    list-style: none;
    position: relative;
    width: 100%;
    margin-bottom: 3rem;
    &:nth-child(2n+2){
        margin-left: 0;
    }
    img{
        display: block;
        max-width: 100%;
        border-radius: var(--borderradius);
    }
    .card-2__info{
        opacity: 1; 
        transition: .2s; 
        transition-delay: .08s; 
    }
    &:nth-child(2n+2){
        .card-2__info{
            left: 0;
            right: auto;
        }
    }
}

.project-image {
    margin: 0;
    display: block;
    padding: 2px 2px 0 0px;
}

//card Caption
.card__captionInner {
    display: block;
}
.card__captionContainer {
    padding: 0;
    margin: 0;
}
.header{
    font-size: var(--p-size);
    font-family: var(--font-main);
    width: 60%;
    line-height: 1em;
    display: inline-block;
    margin:0;
    padding: 0;
}
.list{
    width: calc(40% - 5px);
    display: inline-block;
    margin: 0;
    padding: 0;
}
.listItem{
    display: none;
    margin: 0;
    padding: 0;
}
.year {
    font-size: var(--p-size);       
    text-align: right;
    display: block;
}


@media screen and (min-width: 1200px) {
    .card {
        @media (hover: hover) {
            &:hover{
                .card__captionContainer{
                    opacity: 1; 
                    transition: .2s; 
                    transition-delay: .08s; 
                }
            }
            &:nth-child(2n+2){
                .card__captionInner{
                    left: 0;
                    right: auto;
                    transform: rotate(-4deg);
                }
            }
            .card__captionInner {
                border-radius: var(--borderradius);
                aspect-ratio: 2 / 3;
                min-height: 320px;
                min-width: 200px;
                max-width: 220px;
                box-shadow: var(--buttonshade);
                max-height: calc(100% - 155px);
                margin: auto;
                background-color: var(--green);
                padding: 25px;
                right: 0;
                transform: rotate(4deg);
            }
            .flex{
                display: flex;
                width: 100%;
                height: 100%;
            }
            .card__captionContainer{
                border: none;
                opacity: 0;
                position: absolute;
                z-index: 200;
                transition: 0s;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
            .list{
                font-size: var(--p-size);
                width: calc(100% - 50px);
                display: block;
                position: absolute;
                margin: 0;
                bottom: 30px;
                font-size: 0.7rem !important;
            }
            .listItem{
                font-family: var(--font-mono);
                display: block;
                text-align: left !important;
            }
            .year{
                font-size: 0.7rem !important;
                width: 100%;
                font-weight: bold;
                text-align: left !important;
            }
            .header{
                width: 100%;
                margin-bottom: 2rem;
                text-transform: uppercase;
                text-align: center;
                font-weight: bold;
                font-size: calc(var(--med-size));
                font-family: var(--font-sec);
                letter-spacing: -0.05em;
                line-height: 0.9em;
            }      
        }
    }  
}

@media screen and (min-width: 750px) {
    .card {
        width: auto;
        margin-left: 50%;
        margin-bottom: 0;
        &:nth-child(2n+2){
            margin-right: 50%;
            margin-left: 0;
        }
        img {
            border: none;
        }
    }
}