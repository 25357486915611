.primnav {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 30px;
    z-index: 900;
    ul, li {
        font-size: var(--med-size) !important;
        list-style: none;
        padding: 0 ;
        margin: 0 ;
        transition: .2s;
    }
    a {
        text-decoration: none;
    }
    @media (hover: hover) {
        li:hover {
            padding-left: 0.3em;
        }    
    }
    .about{
        display: block; 
        margin-bottom: 2.5rem;
        cursor: pointer;
    }
}

.languages {
    margin-top: 2.5rem;
    li{
        display: inline-block;
        text-transform: uppercase;
        margin-right: .5rem;
        &:hover {padding: 0;}
        a {
            display: block;
            border-radius: 50%;
            padding: 0rem .5rem !important;

        }        
        a:hover{
            background-color: var(--yellow);
        }
    }
    .active a{
        box-shadow: var(--buttonshade);
        background-color: var(--softYellow);
    }
}

.secnav {
    max-width: var(--maxpagewidth);
    padding: 4px;
    padding-bottom: 0;
    margin: auto;
    font-size: 1rem;
    position: fixed;
    top:4px;
    display: flex;
    gap: 4px;
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 800;
    background-color: var(--bg);
    width: calc(100% - 8px);
    transform: translateY(-250px);
    top: 0;
    left: 0;
    right: 0;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}
.secnav__column {
    display: block;
    flex-grow: 1;
    flex-basis: calc(50% - 2px);
    text-align: center;
    position: relative;
    a {text-decoration: none;}
}
.secnav__item {
    cursor: pointer;
    box-shadow: var(--buttonshade);
    font-size: 1rem;
    margin-bottom: 4px;
    background-color: var(--softYellow);
    border: 1px solid transparent;
    border-bottom: 1px solid var(--dark);
    border-radius: var(--buttonradius);
    color: var(--dark);
    a, span {padding: 0.3rem 1.2rem; display: block;}
    @media (hover: hover) {
        &:hover{
            border-bottom: 1px dashed var(--dark) !important;
            background-color: var(--yellow) !important;
            a, span {color: var(--dark);}
        }
    }
}
.full {
    background-color: white;
    display: block !important;
}
.dark {
    background-color: var(--dark) !important;
    a, span {color: white;}
}
.mobile {
    display: block;
}


.filter{
    position: fixed;
    left: 0;
    right: 0;
    background-color: var(--bg);
    display: none;
    width: 100%;
    //rounded bottom part
    border-radius: var(--buttonradius);
}
.filterItem{
    display: inline-block;
    @media (hover: hover) {
    &:hover{border: 1px solid var(--yellow);}
    }
    &.active{
        background-color: var(--green) !important;
        box-shadow: none;
        border: 1px solid var(--green);
        a, span {color: var(--dark) !important;}
    }
}
.show {
    display: block !important;
}

.tagtitle{
    margin-bottom: 6rem;
}

.info{
    display: none ;
    position: fixed;
    overflow-y: auto;
    left: 0;
    right: 0;
    margin: 0 4px;
    max-height: calc(100vh - 5.8rem);
    text-align:left;
    font-weight: normal;
    letter-spacing: 0em;
    background-color: var(--green); 
    padding: 30px;
    box-shadow: var(--buttonshade);
    font-family: var(--font-main);
    border-radius: var(--buttonradius);
    &.show{display: block ;}
}
.info__inner {
    max-width: 600px;
    margin: auto;
}
.infoList {
    margin: 0;
    margin-top: 1rem !important;
    padding: 0;
    font-size: 0.7rem;
    list-style: none;
    text-align: center;
    li {
        word-break: keep-all ;
        font-family: var(--font-mono) ;
        padding: 0 0.5em;
        margin-bottom: 4px;
        box-shadow: var(--buttonshade);
        border-radius: 10px;
        display: inline-block;
        &.fs{
            margin-left: 4px;
            background-color: var(--pink);
        }
        &.s{
            margin-left: 4px;
            background-color: var(--bg);
        }
    }
}




@media screen and (min-width: 1200px) {
    .primnav{position: absolute; margin: 60px;}
    .secnav {flex-direction: row; background-color: transparent;}
    .secnav__column { flex-grow: 0; }
    .secnav__column:first-child {margin-right: 0px; margin-left: 0;}
    .secnav__item { display: inline-block; font-size: 1rem; background-color: white;}
    .secnav__item span, .secnav__item a {padding: 0.3rem 1rem;}
    .filter {display: block; position: relative; background-color: transparent;}
    .mobile {display: none !important;}
    .info {position: absolute; margin: 0;}
}
@media screen and (max-height: 700px), screen and (max-width: 1200px) {
    .primnav .about{display: none;}
}

