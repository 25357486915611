.btn__wrapper {
  max-width: var(--maxpagewidth);
  position: fixed;
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 4px;
  width: calc(100% -8px);
  z-index: 900;
  justify-content: flex-end
}

.btn{
    display: inline-block;
    font-family: var(--font-mono);
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid black;
    border-radius: 3px;
    box-shadow: var(--buttonshade);
    padding: 1px 6px;
    margin-top: 1rem;
    cursor: pointer;
    text-align: center;
    @media (hover: hover) {
    &:hover{
      border-bottom: 1px dashed black;
    }}
}


.toggle {
  font-family: var(--font-main);
  text-decoration: none;
  display: block;
  font-size: 1rem;
  background-color: var(--dark);
  color: var(--bg);
  border-radius: var(--buttonradius);
  margin: 0;
  box-shadow: var(--buttonshade);
  padding: .5rem 2rem;
  width: 50%;
  min-width: 100px;
  z-index: 3000;
  border: none;
  border-top: 1px solid black;
  @media (hover: hover) {
  &:hover{
    background-color: var(--yellow);
    color: var(--dark);
    border: none;
    border-top: 1px dashed black;  
  }}
}
.hide{display: none !important;}
.forsale{
  font-family: var(--font-main);
  text-decoration: none;
  display: block;
  font-size: 1rem;
  background-color: var(--pink);
  color: var(--dark);
  border-radius: var(--buttonradius);
  margin: 0;
  box-shadow: var(--buttonshade);
  padding: .5rem 2rem;
  width: 100%;
  //flex-basis: calc(50% - 8px);
  min-width: 100px;
  z-index: 3000;
  @media (hover: hover) {
  &:hover{
    background-color: var(--pink);
    color: var(--dark);
  }}
}
@media screen and (min-width: 1200px) {
  .hide{display: block !important;}
}
@media screen and (min-width: 1800px) {
  .btn__wrapper{ right: auto;left: auto; width: 100%;}
}