.start {
  transform: translateZ(0);
  -webkit-transform: translateZ();
  width: calc(100% - 60px);
  left: 0;
  top: 0;
  padding: 30px;
  z-index: 800;
}
.static{
  display: block;
  transition: .05s;
  position: relative;
  margin: 20px;
  transform: translateY(100vh);
  padding-top: 8vh;
  &__inner{
    max-width: 800px;
    margin: auto;
  }
}
.poster__static{
  opacity: 0;
  transition: .1s;
  z-index: 0 ;
  top: 0 ;
  display: block;
  position: relative;
  border-radius: var(--borderradius);
  margin-bottom: 60px;
  padding: var(--poster-padding);
  padding-bottom: 4rem;
  background-color: var(--yellow);
  box-shadow: rgba(17, 12, 46, 0.2) 0px 0px 20px 0px;
  &:first-child{
    background-color: var(--pink);
  }
}
.poster__wrapper{
  display: none;
  position:fixed; 
  top: 0; 
  bottom: 0;
  right: 0; 
  width: 65%; 
  padding: 60px
}

.poster{
  display: block;
  border-radius: var(--borderradius);
  background-color: var(--yellow);
  overflow: hidden;
  padding: var(--poster-padding);
  text-align: left;
  position: relative;
  right: 0;
  bottom: 3em;
  margin: auto;
  box-shadow: rgba(17, 12, 46, 0.2) 0px 0px 20px 0px;
  transition: transform .5s;
  &--inner{
    margin-top: 0;
    position: relative;
    width: 100%;
    height: 100%;
  }
  &--signature {
    bottom: 0;
    padding-top: 3rem;
    img {
      width: 12%;
    }
  }
}

.poster__about {
  box-shadow: rgba(17, 12, 46, 0.2) 0px 0px 20px 0px;
  background-color: var(--pink);
  position: absolute !important;
  margin: 60px !important;
  top: 1rem !important;
  right: 0 !important;
  transition: transform .5s;
  transform: translateX(800px) rotate(0deg);
  z-index: 200;
  &.show{transform: translateX(-20px) translateY(-20px) rotate(5deg) !important;}
}


.sun {
  border: none;
  display: flex;
  position: fixed;
  text-decoration: none;
  top: calc(50% - 25vh);
  right: 30px;
  width: 45vw;
  max-width: 200px;
  max-height: 200px;
  height: 45vw;
  text-align: center;
  font-size: var(--med-size);
  background-color: var(--yellow);
  box-shadow: rgba(17, 12, 46, 0.2) 0px 0px 20px 0px;
  border-radius: 50%;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 800;
  &:hover{
    animation-play-state: paused;
    -webkit-animation-play-state: paused; /* Safari and Chrome */
  }
  &__inner{
    z-index: 2;
    display: block;
    margin: auto;
  }
  &::before{
    content: '';
    position: absolute;
    background-color: var(--bg);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    opacity: .8;
    filter: blur(40px);
    -webkit-filter: blur(40px);
  }
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}


 
@media screen and (min-width: 1200px) {
  .sun{
    display: none;
  }
  .poster__wrapper, .button, .about {
    display: block;

  }
  .hidePoster{display:none !important}
  .start {
    width: 35%;
    padding: 60px;
    position: fixed;
  }
  .poster {
    display: block;
    position: relative;
    bottom: auto;
    top: 0;
    margin-right: 0;
    height: calc(100vh - 240px);
    max-width: 480px;
    min-height: 500px;
    max-height: 700px;
    aspect-ratio: 2 / 3;
    &--signature {
      position: absolute;
    }
  }
}

@media screen and (max-height: 700px), screen and (max-width: 1200px) {
  .poster, .poster__about{display: none !important;}
  .sun{display: flex !important;}
}