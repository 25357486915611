.logo{
    width: 100%;
    max-width: 500px;
    display: none;
    align-items: flex-start;
    transition: .25;
    svg{
      width: 100%;
    }
    &::selection{
      background: transparent;
    }
    &:active, &:focus{
      background: transparent;
    }
  }
  .poster--inner {
    .logo {
      transform: translate(0px, 0px) !important; 
      margin: auto;
      width: 80% !important;
      margin-bottom: 3rem !important;
    }
  }
  
  .path {
    &--1 {
      display: none;
    }
    &--2 {
      stroke: black;
      display: block;
      stroke-dasharray: 1000;
      animation-delay: 5s;
      -o-animation-delay: 5s;
      animation: dash 3s ease-out alternate;
  
    }
  }
  
  .a {
    stroke-dasharray: 1000;
    animation-delay: 2s;
    -o-animation-delay: 2s;
    animation: dash 2s ease-out alternate;
  }
  @keyframes dash {
    from {
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  
  