.h1 {
    font-family:  var(--font-sec);
    font-size: var(--large-size);
    letter-spacing: -0.05em;
    color: black;
    text-align: center;
    text-transform: uppercase;
    line-height: 0.9em;
    margin: 0em 0 1em 0;
}

.h2 {
    font-size: var(--med-size);
    line-height: 1.2rem;
    font-weight: 600;
    font-family: var(--font-sec);
}
.p, p{
    font-size: var(--p-size) !important;
    font-family: var(--font-main);
    line-height: 1.5em;
    margin: 1.5em 0 0.5em 0 !important;
    a, em {
        font-family: var(--font-mono);
        font-weight: 300;
        text-decoration: none;
        font-style: normal;
        color: rgb(0, 0, 0);
    }
    em {
        border-bottom: 1px dashed black;
    }
    a {
        border-bottom: 1px solid black;
        @media (hover: hover) {
        &:hover {
            border-bottom: 1px dashed;
        }}
    }
}
a {
    color: black;
}